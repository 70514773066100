export default {
    1: [
        'Venue and open booking search',
        'Request to book',
        'New music alerts',
        'Advertise your pay scale',
        'Artist “classifieds” to find equipment of bandmates',
        'Visible account analytics, including page views, link clicks, music plays and “Book Me.”',
        'Name',
        'Stage name',
        'Music genre',
        'One song demo upload',
        '100 word bio',
    ],
    2: [
        'Venue and open booking search',
        'Request to book',
        'New music alerts',
        'Advertise your pay scale',
        'Artist “classifieds” to find equipment of bandmates',
        'Visible account analytics, including page views, link clicks, music plays and “Book Me.”',
        'Name',
        'Stage name',
        'Profile photo',
        'Music genre',
        'Multiple song/video uploads',
        'Limitless bio',
        'Link to booking calendar',
        'Link to social media/music accounts',
        'Link to artist webpage',
        'Link to merch store on your artist site',
        'Calendar for availability',
        'BOOK ME button',
    ],
}
