<template>
    <dashboard-layout>
        <v-row align-content="stretch">
            <v-col
                v-for="plan in pricePlans"
                :key="plan.id"
                cols="12"
                md="6"
                >
                <v-card class="fill-height">
                    <v-card-title>
                        {{ plan.name }}
                        <span
                            v-if="activePricePlan && activePricePlan.id == plan.id"
                            class="ml-4 text-subtitle-1"
                            >
                            (Current Plan)
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <ul>
                            <li v-for="(benefit, index) in planBenefits[plan.id]"
                                :key="`benefit-${index}`">
                                {{ benefit }}
                            </li>
                        </ul>
                    </v-card-text>
                    <v-card-actions v-if="activePricePlan && activePricePlan.id != plan.id">
                        <v-btn @click="handlePlanSelect(plan)">
                            <template v-if="activePricePlan && activePricePlan.id < plan.id">
                                Update
                            </template>
                            <template v-else>
                                Select
                            </template>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import PricePlan from "@/models/PricePlan";
import DashboardLayout from "@/layouts/DashboardLayout";
import planBenefits from "./planBenefits";

export default {
    name: "SelectPlan",
    components: {DashboardLayout},
    data: function () {
        return {
            pricePlans: [],
            activePricePlan: {},
            planBenefits: planBenefits,
        }
    },
    methods: {
        handlePlanSelect(plan) {
            this.$router.push({name: 'dashboard.billing.checkout', params: {plan}})
        }
    },
    async mounted() {
        this.pricePlans = await PricePlan.get()
        if (this.$auth.user().price_plan_id) {
            this.activePricePlan = await PricePlan.find(this.$auth.user().price_plan_id)
        }
    },
}
</script>

<style scoped>

</style>
